<template>
	<div class="projecet-container">
		<div class="project-main">
			<div class="main-left">
				<div class="header-container">
					<div class="header-left">
						<el-button @click="addSpace()" v-if="spaceAction.addproject" type="primary">{{$t('project.create')}}</el-button>
					</div>
					<div class="header-right">
						<el-input class="marginRight" @keyup.enter.native="search()" v-model="searchValue" :placeholder="$t('common.search')"></el-input>
						<div class="hdBtnBox">
							<el-button type="primary" icon="el-icon-search" class="hdBtnBox-item" @click="search()">{{$t('common.search')}}</el-button>
							<el-button type="primary" icon="el-icon-sort" class="hdBtnBox-item" @click="sort(projectShowList,position)">{{$t('common.sort')}}</el-button>
							<!-- <el-button type="primary" v-if="projectOrCode>0" @click="projectOrCode=-1" ><i class="iconfont icon-fanhui marginRight"></i>返回</el-button> -->
						</div>
					</div>
				</div>
				<div class="project-box" v-if="projectOrCode==-1">
					<div class="item-container" v-for="item in projectShowList" :key="item.id">
						<p class="item-title" :style="(spaceAction.deleteprojecttype||spaceAction.updateprojecttype)?'':'justify-content:center;'">
							<span style="font-size: 18px;font-weight: bold;color: transparent;" v-if="spaceAction.updateproject||spaceAction.deleteproject">...</span>
							<span class="item-name-box" :title="item.name">{{item.name}}</span>
							<el-dropdown trigger="click" v-if="spaceAction.updateproject||spaceAction.deleteproject">
								<span class="el-dropdown-link">
									<i class="el-icon-more"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<!-- <el-dropdown-item v-if="spaceAction.deleteproject" @click.native="deleteSpace(item)"><span class="el-icon-delete"></span>删除</el-dropdown-item> -->
									<el-dropdown-item v-if="spaceAction.updateproject" @click.native="setSpace(item)"><span class="el-icon-setting"></span>{{$t('project.itemSetting')}}</el-dropdown-item>
									<el-dropdown-item v-if="spacePermiss.isSpaceOwner||spacePermiss.isSmartAdmin" @click.native="deleteSpace(item)"><span class="el-icon-delete"></span>{{$t('common.delete')}}</el-dropdown-item>
									<!-- <el-dropdown-item v-if="spaceAction.updateproject" @click.native="uploadImg(item)"><span class="el-icon-upload2"></span>上传图片</el-dropdown-item> -->
								</el-dropdown-menu>
							</el-dropdown>
							<span v-else> </span>
						</p>
						<div class="item-img" @click="intoProjects(item)">
							<el-image v-if="true" src="../assets/default/project.png" alt fit="contain" style="width:190px;height:100px"
							 :onerror="errorImg" />
							<el-image v-else :src="item.coverPictureService" :onerror="errorImg" fit="contain" style="width:190px;height:100px"></el-image>
							<!-- <p class="own-text1">{{item.fileSizeDto.sizeStr}}</p> -->
						</div>
					</div>
				</div>
				<!-- 编码库 -->
				<div class="code" v-if="projectOrCode==1">
					<Excel></Excel>
				</div>
				<!-- 资产参数 -->
				<div class="code" v-if="projectOrCode==2">
					<TemplateCom :space="isSpace"></TemplateCom>
				</div>
			</div>
		</div>
		<el-dialog title="加入成员" :visible.sync="dialogVisible" :close-on-click-modal="false">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

				<el-form-item label="邮箱" prop="email">
					<el-input v-model="ruleForm.email"></el-input>
				</el-form-item>
				<div class="inline-box">
					<el-form-item label="所属项目" prop="belongPro">
						<el-select v-model="ruleForm.belongPro" placeholder="请选择">
							<el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="权限" prop="juris">
						<el-select v-model="ruleForm.juris" placeholder="请选择">
							<el-option v-for="item in jurisdiction" :key="item.id" :label="item.label" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</div>
			</el-form>
			<p style="text-align: right;">
				<el-button type="primary" icon="el-icon-success" @click="addMember">确定</el-button>
			</p>
		</el-dialog>
		<el-dialog :title="addOrEdit=='add'?$t('project.create'):$t('project.editItem')" :visible.sync="showDialog" width="45% !important"
		 :close-on-click-modal="false" center>
			<el-form ref="ruleForm1" :model="ruleForm1" :rules="rules1" label-width="100px" label-position="left" style="text-align: left;padding:20px">
				<el-form-item :label="$t('project.name')" prop="ProjectName">
					<el-input v-model="ruleForm1.ProjectName" :placeholder="$t('project.name')" />
				</el-form-item>
				<el-form-item :label="$t('project.coding')" prop="ProjectDescription">
					<el-input v-model="ruleForm1.ProjectDescription" :placeholder="$t('project.coding')" />
				</el-form-item>
				<el-form-item :label="$t('project.address')">
					<el-input v-model="ruleForm1.Address" :placeholder="$t('project.address')" />
				</el-form-item>
				<el-form-item :label="$t('project.cover')" v-if="addOrEdit=='add'">
					<el-upload ref="uploadAdd" :show-file-list="false" action list-type="picture-card" :http-request="adduploadPicture"
					 :on-change="addgetPicture" accept=".bmp,.jpg,.png,.jpeg,.BMP,.JPG,.PNG,.JPEG">
						<img v-if="addPictureUrl" :src="addPictureUrl" class="avatarPic">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item v-for="(items,i) in comList" :key="i" :label="$t('project.monomer')+(i+1)">
					<div class="customAdd">
						<el-input :placeholder="$t('project.monomerName')" v-model="comList[i].MonomerName">
						</el-input>
						<el-input :placeholder="$t('project.monomercoding')" v-model="comList[i].MonomerCode">
						</el-input>
						<el-input :placeholder="$t('project.floorone')" type="number" v-model.number="comList[i].FloorUpNum" :min="0" oninput="value=value.replace(/[^\d]/g,'')">
						</el-input>
						<el-input :placeholder="$t('project.floorTwo')" type="number" v-model.number="comList[i].FloorDownNum" :min="0" oninput="value=value.replace(/[^\d]/g,'')">
						</el-input>
						<el-button v-show="i==0" @click="addCuston(i)" type="primary" icon="el-icon-plus"></el-button>
						<div class="btn-red">
							<el-button v-show="i!=0" @click="minusCuston(i,items)" type="primary" icon="el-icon-minus"></el-button>
						</div>
					</div>
				</el-form-item>
			</el-form>
			<div class="addBtn">
				<el-button type="danger" @click="showDialog=false">{{$t('common.cancel')}}</el-button>
				<el-button type="primary" @click="submit('ruleForm1')" v-if="addOrEdit=='add'">{{$t('common.confirmation')}}</el-button>
				<el-button type="primary" @click="submit('ruleForm1')" v-else>{{$t('common.save')}}</el-button>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="editDialog" :close-on-click-modal="false" title="上传封面图">
			<el-form label-width="80px" label-position="left">
				<el-form-item label="封面图" style="text-align:left">
					<el-upload ref="uploadAdd" :show-file-list="false" action list-type="picture-card" :http-request="uploadPicture"
					 :on-change="getPicture" accept=".bmp,.jpg,.png,.jpeg,.BMP,.JPG,.PNG,.JPEG">
						<img v-if="PictureUrlService" :src="PictureUrlService" class="avatarPic">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
			</el-form>
			<div style="text-align:center;">
        <el-button type="danger" @click="editDialog = false">{{$t('common.cancel')}}</el-button>
				<el-button type="primary" @click="changeImg">{{$t('common.confirmation')}}</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
import {
  mapState,
  mapMutations
} from 'vuex'
import TopBar from '@/components/layout/TopBar.vue'
import Excel from '@/components/excel/netExcel.vue'
import UserCom from '@/components/userCom.vue'
import TemplateCom from '../views/system/TemplateCom.vue'
import {
  authority
} from '@/mixins/authorityProject'
var formData = new FormData()
var addformData = new FormData()
export default {
  name: 'One',
  mixins: [authority],
  data () {
    return {
      position: false,
      editDialog: false,
      addPictureUrl: '',
      PictureUrlService: '',
      errorImg: 'this.src="' + require('../assets/default/project.png') + '"',
      addOrEdit: 'add',
      projectOrCode: -1,
      returnProject: false,
      ruleForm: {
        email: '',
        juris: '',
        belongPro: ''
      },
      isSpace: 'space',
      rules: {
        email: {
          required: true,
          message: '请填写邮箱',
          trigger: 'blur'
        },
        juris: {
          required: true,
          message: '请选择所属项目',
          trigger: 'change'
        },
        belongPro: {
          required: true,
          message: '请选择权限',
          trigger: 'change'
        }
      },
      SearchDataList: [],
      dialogVisible: false,
      projectList: [],
      projectShowList: [],
      jurisdiction: [{
        label: '管理员',
        id: 1
      }, {
        label: '普通成员',
        id: 2
      }],
      userList: [{
        imgUrl: require('../assets/header.png'),
        name: 'Mark',
        teml: '98756464@qq.com'
      }, {
        imgUrl: require('../assets/header.png'),
        name: 'Mark',
        teml: '98756464@qq.com'
      }, {
        imgUrl: require('../assets/header.png'),
        name: 'Mark',
        teml: '98756464@qq.com'
      }, {
        imgUrl: require('../assets/header.png'),
        name: 'Mark',
        teml: '98756464@qq.com'
      }],
      searchValue: '',
      imgUrl: require('../assets/noapce.png'),
      showDialog: false,
      ruleForm1: {
        ProjectName: '',
        ProjectDescription: '',
        Address: ''
      },
      rules1: {
        ProjectName: [{
          required: true,
          message: '项目名不能为空',
          trigger: 'blur'
        }, {
          validator: this.$utils.nameCheck,
          trigger: 'change'
        }],
        ProjectDescription: [{
          required: true,
          message: '项目编码不能为空',
          trigger: 'blur'
        }]
      },
      comList: [{
        MonomerName: '',
        MonomerCode: '',
        FloorUpNum: '',
        FloorDownNum: '',
        FloorUpList: [{
          FloorName: '',
          FloorCode: ''
        }],
        FloorDownList: [{
          FloorName: '',
          FloorCode: ''
        }],
        id: 0
      }],
      viewTitle: ''
    }
  },
  props: {
    msg: String
  },
  mounted () {
    this.init()
    this.viewTitle = localStorage.getItem('projectName')
  },
  computed: {
    ...mapState('spaceAction', {
      spaceAction: 'spaceAction'
    }),
    ...mapState('spacePermiss', {
      spacePermiss: 'spacePermiss'
    })
  },
  methods: {
    ...mapMutations('project', {
      initProject: 'initProject'
    }),
    addMember () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          console.log('校验通过')
        } else {
          return false
        }
      })
    },
    sort (arr, pos) {
      if (pos) {
        arr.sort((a, b) => a['nickName'] < b['nickName'] ? 1 : -1)
      } else {
        arr.sort((a, b) => a['nickName'] > b['nickName'] ? 1 : -1)
      }
      this.position = !this.position
    },
    addCuston (i) {
      this.comList.push({
        MonomerName: '',
        MonomerCode: '',
        FloorUpNum: '',
        FloorDownNum: '',
        new: true
      })
    },
    minusCuston (i, item) {
      this.comList.splice(i, 1)
    },
    init () {
      this.$axios.get('/api/Project/GetProjectBySpaceId?spaceId=' + localStorage.eleSpaceId).then(res => {
        var projectList = []

        var indexPro = res.find(n => n.id == localStorage.eleProjectTypeId)
        if (indexPro) {
          projectList = indexPro.projectListDto
        }
        for (var i = 0; i < projectList.length; i++) {
          projectList[i].src = ''
          projectList[i].positionNum = i + 1
          projectList[i].projectId = projectList[i].id
          projectList[i].id = 'card' + projectList[i].id
          projectList[i].name = projectList[i].projectName
        }
        this.SearchDataList = projectList
        this.projectList = this.SearchDataList
        this.projectShowList = this.projectList
      })
    },
    async intoProjects (project) {
      let apiData = {}
      await this.$axios.get('/api/Project/GetUserPermissionByprojectId?projectId=' + project.projectId).then(res => {
        apiData = res
        var userMessage = JSON.parse(localStorage.getItem('USER_INFO'))
        userMessage.roles = res.roles
        localStorage.setItem('USER_INFO', JSON.stringify(userMessage))
      })
      var that = this
      var spaceId = localStorage.eleSpaceId
      var projectTypeID = project.projectTypeId
      var SpaceCode = localStorage.enterpriseCode
      var loginUserId = JSON.parse(localStorage['USER_INFO']).userId
      // await this.$db.transaction(function (tx) {
      //   // 如果表中数据为空，则插入数据
      //   tx.executeSql('SELECT * FROM ProjectView1', [], function (tx, res) {
      //     var haveView = false
      //     for (var i = 0; i < res.rows.length; i++) {
      //       var view = JSON.parse(JSON.stringify(res.rows[i]))
      //       if (loginUserId == view.UserID & project.projectId == view.ProjectID) {
      //         haveView = true
      //       }
      //     }
      //     if (haveView) {
      //       tx.executeSql('DELETE FROM ProjectView1 WHERE ProjectID = ? and UserID = ?', [project.projectId,
      //         loginUserId
      //       ])
      //     }
      //     var dateTime = that.getFullTime()
      //     var guid = that.guid()
      //     var sql =
			// 				"insert into ProjectView1(Guid, SpaceID, ProjectTypeID, ProjectID, UserID, ProjectName,SpaceCode,ProjectCode, DateTime)values('" +
			// 				guid + "'," + spaceId + ',' + projectTypeID + ',' + project.projectId + ',' + loginUserId + ",'" + project.name +
			// 				"','" + SpaceCode + "','" + project.projectDescription + "','" + dateTime + "')"
      //     tx.executeSql(sql)
      //   }, function (tx, err) {
      //     console.log(err)
      //   })
      // })
      // 目前默认为
      localStorage.setItem('projectCode', project.projectDescription)
      localStorage.setItem('eleProjectId', project.projectId)
      localStorage.setItem('projectName', project.name)
      await this.authority(apiData)
      this.initProject(this.projectShowList)
      // this.flag = true
      // console.log(1111111)
      // this.$router.push('/home')
      // 进入项目之后执行权限管理相关操作
    },
    search () {
      var value = this.searchValue
      if (value == '') {
        this.projectShowList = this.SearchDataList
      } else {
        this.projectShowList = this.SearchDataList.filter(res => {
          return res.name.indexOf(value) != -1
        })
      }
    },
    startDrag (event, id) {
      console.log(event)
      console.log(id)
    },
    swicthPosition (oldPositon, newPositon, originItem) {
      console.log(oldPositon) // 卡片原来的位置号码
      console.log(newPositon) // 卡片需要交换的位置号码
      console.log(originItem) // 卡片交换完成后的数据
    },
    finishDrag (oldPositon, newPositon, originItem) {
      console.log(oldPositon) // 卡片原来的位置号码
      console.log(newPositon) // 卡片需要交换的位置号码
      console.log(originItem) // 卡片交换完成后的数据
    },
    addSpace () {
      this.ruleForm1 = {
        ProjectName: '',
        ProjectDescription: '',
        Address: ''
      },
      addformData = new FormData()
      this.comList = [{
        MonomerName: '',
        MonomerCode: '',
        FloorUpNum: '',
        FloorDownNum: '',
        FloorUpList: [{
          FloorName: '',
          FloorCode: ''
        }],
        FloorDownList: [{
          FloorName: '',
          FloorCode: ''
        }],
        id: 0
      }]
      this.addPictureUrl = ''
      this.addOrEdit = 'add'
      this.showDialog = true
    },
    addformData (formData, num, com, FloorCode, FloorName) {
      formData.append('comDtoList[' + num + '].MonomerName', com.MonomerName)
      formData.append('comDtoList[' + num + '].MonomerCode', com.MonomerCode)
      formData.append('comDtoList[' + num + '].FloorName', FloorName)
      formData.append('comDtoList[' + num + '].FloorCode', FloorCode)
    },
    // 提交项目时，对单体的判断问题
    jugMonomer (formData) {
      var numAll = 0
      for (var i = 0; i < this.comList.length; i++) {
        var comListTemp = JSON.parse(JSON.stringify(this.comList))
        var com = comListTemp[i]
        if ((com.MonomerName == '' && com.MonomerCode != '') || (com.MonomerName != '' && com.MonomerCode == '')) {
          this.$message({
            type: 'warning',
            message: '单体名称和编码必须统一有无'
          })
          return false
        }
        if ((com.FloorDownNum != '' || com.FloorUpNum != '') && com.MonomerName == '') {
          this.$message({
            type: 'warning',
            message: '有楼层时单体名称和编码不能为空'
          })
          return false
        }
        if ((com.FloorDownNum == '' && com.FloorUpNum == '') && com.MonomerName != '') {
          this.$message({
            type: 'warning',
            message: '有单体时地上和地下楼层个数，至少有一个不为空'
          })
          return false
        }
        // 判断重复单体名称和编码问题
        comListTemp.splice(i, 1)
        var index = comListTemp.findIndex(n => n.MonomerName == com.MonomerName)
        var indexCode = comListTemp.findIndex(n => n.MonomerCode == com.MonomerCode)
        if (index > -1) {
          var ii = i + 1
          this.$message({
            type: 'warning',
            message: "'单体" + ii + "':名称(" + com.MonomerName + ')有重复'
          })
          return false
        }
        if (indexCode > -1) {
          var ii = i + 1
          this.$message({
            type: 'warning',
            message: "'单体" + ii + "':编码(" + com.MonomerCode + ')有重复'
          })
          return false
        }
        // 都为空时说明
        if (com.MonomerName == '' && com.MonomerCode == '' && com.FloorUpNum == '' && com.FloorDownNum == '') {
          console.log('有都为空的数据')
        } else {
          // 将数据添加到formData
          if (com.FloorDownNum != '' && com.FloorDownNum > 0) {
            if (com.FloorUpNum != '' && com.FloorUpNum > 0) {
              for (var up = 0; up < com.FloorUpNum; up++) {
                var floor = up + 1
                this.addformData(formData, numAll + up, com, 'L' + floor, 'L' + floor)
              }
              for (var down = 0; down < com.FloorDownNum; down++) {
                var downNum = numAll + down + com.FloorUpNum
                var floor = down + 1
                this.addformData(formData, downNum, com, 'B' + floor, 'B' + floor)
              }
              numAll += com.FloorUpNum + com.FloorDownNum
            } else {
              for (var down = 0; down < com.FloorDownNum; down++) {
                var floor = down + 1
                this.addformData(formData, down, com, 'B' + floor, 'B' + floor)
              }
              numAll += com.FloorDownNum
            }
          } else {
            if (com.FloorUpNum != '' && com.FloorUpNum > 0) {
              for (var up = 0; up < com.FloorUpNum; up++) {
                var floor = up + 1
                this.addformData(formData, up, com, 'L' + floor, 'L' + floor)
              }
              numAll += com.FloorUpNum
            }
          }
        }
      }
    },
    // 判断addformData是否有该属性
    haveAttu (name, value) {
      if (addformData.get(name) == null) {
        addformData.append(name, value)
      } else {
        addformData.set(name, value)
      }
    },
    submit (ruleForm1) {
      this.$refs[ruleForm1].validate(valid => {
        if (valid) {
          console.log(this.comList)
          var jugM = this.jugMonomer(addformData)
          if (jugM == false) {
            return
          }

          if (this.addOrEdit == 'add') {
            // 判断addformData是否有该属性
            this.haveAttu('ProjectName', this.ruleForm1.ProjectName)
            this.haveAttu('ProjectDescription', this.ruleForm1.ProjectDescription)
            this.haveAttu('Address', this.ruleForm1.Address)
            this.$axios.post('/api/Project/AddProject', addformData).then(res => {
              this.$message({
                type: 'success',
                message: '添加成功'
              })
              this.showDialog = false
              this.init()
            })
          } else {
            this.comList.forEach(e => {
              if (e.FloorDownNum == '') {
                e.FloorDownNum = 0
              }
              if (e.FloorUpNum == '') {
                e.FloorUpNum = 0
              }
            })
            this.ruleForm1.ComponentNumDtos = this.comList
            console.log(this.comList)
            this.$axios.post('/api/Project/UpdateProject', this.ruleForm1).then(res => {
              this.$message({
                type: 'success',
                message: '修改成功'
              })
              var id = this.ruleForm1.Id
              var index = this.projectList.findIndex(n => n.projectId == id)
              this.projectList[index].address = this.ruleForm1.Address
              this.projectList[index].projectName = this.ruleForm1.ProjectName
              this.projectList[index].name = this.ruleForm1.ProjectName
              this.projectList[index].projectDescription = this.ruleForm1.ProjectDescription
              this.showDialog = false
            })
          }
        }
      })
    },
    getFullTime () {
      let date = new Date() // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + ''
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
      let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
      let h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours())
      let m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes())
      let s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds())
      return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
    },
    guid () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0
        var v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    },
    deleteSpace (item) {
      this.$confirm('此操作将删除该项目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/api/Project/DeleteProject?projectId=' + item.projectId).then(res => {
          this.$message({
            type: 'success',
            message: res
          })
          var index = this.projectList.findIndex(n => n.id == item.id)
          this.projectList.splice(index, 1)
        })
      })
    },
    setSpace (item) {
      this.$axios.get('/api/Project/GetProjectById?projectId=' + item.projectId).then(res => {
        console.log(res.comDtoList)
        this.comList = []
        this.ruleForm1 = {
          Id: res.id,
          ProjectName: res.projectName,
          ProjectDescription: res.projectDescription,
          Address: res.address
        }
        if (res.comDtoList.length > 0) {
          for (var i = 0; i < res.comDtoList.length; i++) {
            var index = this.comList.findIndex(n => n.MonomerName == res.comDtoList[i].monomerName)
            if (index > -1) {
              if (res.comDtoList[i].floorName.indexOf('L') > -1) {
                this.comList[index].FloorUpNum += 1
              } else {
                this.comList[index].FloorDownNum += 1
              }
            } else {
              if (res.comDtoList[i].floorName.indexOf('L') > -1) {
                this.comList.push({
                  MonomerName: res.comDtoList[i].monomerName,
                  MonomerCode: res.comDtoList[i].monomerCode,
                  FloorUpNum: 1,
                  FloorDownNum: 0
                })
              } else {
                this.comList.push({
                  MonomerName: res.comDtoList[i].monomerName,
                  MonomerCode: res.comDtoList[i].monomerCode,
                  FloorUpNum: 0,
                  FloorDownNum: 1
                })
              }
            }
          }
          console.log(this.comList)
        } else {
          this.comList = [{
            MonomerName: '',
            MonomerCode: '',
            FloorUpNum: '',
            FloorDownNum: '',
            id: 0
          }]
        }
      })
      this.addOrEdit = 'edit'
      this.showDialog = true
    },
    uploadImg (item) {
      this.ruleForm1 = {
        Id: item.projectId,
        ProjectName: item.projectName,
        ProjectDescription: item.projectDescription,
        Address: item.address
      }
      this.editDialog = true
      this.PictureUrlService = item.coverPictureService
      formData = new FormData()
      if (this.PictureUrlService) {
        var index = this.PictureUrlService.indexOf('Doc/Project')
        var url = this.PictureUrlService.substring(index, this.PictureUrlService.length)
        formData.append('CoverPicture', url)
      }
    },
    uploadPicture (file) {
      formData.append('FormFile', file.file)
    },
    getPicture (file, fileList) {
      this.PictureUrlService = file.url
    },
    adduploadPicture (file) {
      addformData.append('FormFile', file.file)
    },
    addgetPicture (file, fileList) {
      this.addPictureUrl = file.url
    },
    changeImg () {
      if (this.PictureUrlService) {
        var id = this.ruleForm1.Id
        formData.append('Id', this.ruleForm1.Id)
        formData.append('statu', 3)
        this.$axios.post('/api/Project/UpdateCoverPicture', formData).then(res => {
          this.$message({
            type: 'success',
            message: '上传成功'
          })
          var index = this.projectList.findIndex(n => n.projectId == id)
          this.projectList[index].coverPictureService = res.headPortrait
          this.editDialog = false
        })
      }
    }
  },
  created () {
    // this.$db.transaction(function (tx) {
    //   // 创建表
    //   console.log(tx);
    //   tx.executeSql(
    //     'CREATE TABLE IF NOT EXISTS ProjectView1 (Guid unique  primary key,SpaceID, ProjectTypeID, ProjectID, UserID, ProjectName,SpaceCode, ProjectCode, DateTime)'
    //   )
    // })
    console.log(this.$db);
  //   this.$db.onupgradeneeded((event)=>{
  //   let  db = event.target.result;
  //   var objStore;
  // if (!db.objectStoreNames.contains('ProjectView1')) {
  //   // 创建新的存储对象  表名 { 主键, 是否自增(默认 false ) } 
  //   objStore = db.createObjectStore('ProjectView1', { SpaceID: 'id', autoIncrement: true });
  //   // 新建索引 名称 属性 配置对象
  //   objStore.createIndex('SpaceID', 'SpaceID', { unique: false });
  //   objStore.createIndex('ProjectTypeID', 'ProjectTypeID', { unique: false });
  //   objStore.createIndex('ProjectID', 'ProjectID', { unique: true });
  //   objStore.createIndex('UserID', 'UserID', { unique: true });
  //   objStore.createIndex('ProjectName', 'ProjectName', { unique: true });
  //   objStore.createIndex('SpaceCode', 'SpaceCode', { unique: true });
  //   objStore.createIndex('ProjectCode', 'ProjectCode', { unique: true });
  //   objStore.createIndex('DateTime', 'DateTime', { unique: true });
  // }
  //   })
  },
  components: {
    // cardDragger,
    TopBar,
    Excel,
    UserCom,
    TemplateCom
  }
}
</script>

<style lang="less" scoped>
	.projecet-container {
		display: flex;
		justify-content: space-between;

		.el-button--primary {
			background-color: #475065;
			border: none;
		}

		.leftMenu {
			width: 100%;
			// height: calc(100vh - 90px);
			// width: 76%;
			min-height: calc(100vh - 90px);
			background-color: #2f374a;

			ul {
				font-size: 16px;
				color: #ebebeb;
				font-weight: normal;

				li {
					height: 50px;
					line-height: 50px;

				}

				li:nth-child(1) {
					font-size: 14px;
					color: #7e7e7e;
				}

				li:nth-child(2),
				li:nth-child(3) {
					cursor: pointer;
					// margin-left: 40px;
				}

				.actived {
					background-color: #1a9ed2;
				}
			}
		}

		.project-main {
			min-height: calc(100vh - 80px);
			height: 877px;
			min-width: calc(100vw - 136px);
			width: 1760px;
			// width: calc(100vw - 130px);
			background-color: #e8e8e8;
			padding: 15px 25px;
			box-sizing: border-box;

			.main-left {
				// width: 76%;
				width: 100%;
				// height: calc(100vh - 90px);
				// width: 76%;
				min-height: calc(100vh - 90px);

				.header-container {
					display: flex;
					// height: 70px;
					justify-content: space-between;
					align-items: center;
					padding: 0 20px 0 0;
					font-size: 18px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #343434;
					margin-bottom: 10px;

					.header-left {
						// background-color: #ffffff;
						// padding: 15px 15px 15px 30px;
						// border-radius: 8px;
						// width: 230px;
						// text-align: left;
						// box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
						// i {
						// 	float: right;
						// 	margin-right: 10px;
						// }
					}

					.header-right {
						display: flex;
						height: 40px;

						.hdBtnBox {
							display: flex;
							margin-left: 10px;

							// .search {
							// 	background-color: #475065;
							// 	border: none;
							// }
							.marginRight {
								margin-right: 5px;
							}

							// .el-button--primary:nth-child(3) {
							// 	background-color: #475065;
							// }

							//   .hdBtnBox-item {
							//   	background-color: #475065;
							//   	border: none;
							// color: #ffffff;
							//   }
							.hdBtnBox-item2 {
								background-color: #a5a5a5;
							}
						}
					}
				}

				.project-box {
					height: calc(100vh - 140px);
					min-height: 797px;
					overflow-y: auto;
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-start;
					align-content: flex-start;

					.addSpace {
						padding-top: 100px;
						box-sizing: border-box;
						width: 190px;
						height: 268px;
						background: #FFFFFF;
						box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
						border-radius: 2px;
						cursor: pointer;
						margin: 10px 20px 10px 0;

						span {
							font-size: 44px;
							color: #d8d8d8;
						}

						p {
							font-size: 18px;
							font-family: PingFangSC-Medium, PingFang SC;
							font-weight: 500;
							color: #444444;
							line-height: 25px;
							margin-top: 5px;
						}
					}

					.item-container {
						position: relative;
						margin: 10px 20px 10px 0;
						width: 190px;
						height: 145px;
						background: #FFFFFF;
						box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
						border-radius: 2px;

						.item-title {
							display: flex;
							align-items: center;
							justify-content: space-between;
							height: 42px;
							background: #F7F7F8;
							box-sizing: border-box;
							padding: 0 20px;
							border-top: 4px solid #414141;
							font-weight: 500;
							color: #444444;

							.item-name-box {
								// background-color: #000000;
								max-width: 200px;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
						}

						.item-img {
							// position: relative;
							// height: 226px;
							cursor: pointer;
							// .img{
							// 	position: absolute;
							// 	left: 50%;
							// 	top: 50%;
							// 	transform: translate(-50%,-50%);
							// }
							.own-text1 {
								position: absolute;
								bottom: 5px;
								right: 5px;
								background-color: #2f374a;
								color: #fff;
								padding:0 5px;
							}
						}

					}
				}
			}
		}

		.el-dialog__header {
			text-align: left;
			background-color: #f8f8f9;
		}

		.el-form-item__content {
			margin: 0 !important;
		}

		.el-dialog__body {
			padding: 10px 20px;
		}

		.el-dialog {
			p {
				text-align: left;
				height: 40px;
				line-height: 40px;
			}

		}

		.el-form-item__label {
			// width: 0;
			text-align: left;
		}

		.inline-box {
			display: flex;
			justify-content: space-between;

			.el-form-item {
				width: 45%;
			}
		}

	}

	.add {
		display: flex;
		padding: 10px;

		p {
			margin-top: 13px;
			margin-right: 10px;
		}

		.el-input {
			width: 80%;
		}
	}

	.addBtn {
		margin-left: 74%;
		margin-top: 20px;
	}

	.customAdd {
		display: flex;

		div:nth-child(1) {
			margin-left: 0;
		}

		div {
			margin: 0 10px;
		}

		.btn-red {
			margin: 0;

			.el-button--primary {
				color: #FFF;
				// 	background-color: #ff0000 !important;
				// 	border-color: #ff0000 !important;
			}
		}
	}

	// .main {
	//   display: flex;
	//   justify-content: center;
	//   height: 800px;
	//   background-color: unset;
	// }
	// .el-header {
	//     -webkit-box-shadow: 0 2px 4px rgba(0,0,0,.08);
	//     box-shadow: 0 2px 4px rgba(0,0,0,.08);
	//     color: #333;
	//     text-align: center;
	//     line-height: 60px;
	//     padding: 0px;
	// }
	// .img{
	//   cursor: pointer;
	// }
</style>
