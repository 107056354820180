<template>
	<div class="com">
		<div class="item-header">
			<span>成员管理</span>
			<p>
				<!-- <span class="el-icon-search"></span> -->
				<!-- <span @click="sort(userList,position)" class="el-icon-sort"></span> -->
				<!-- <span @click="pullUser()" class="el-icon-s-custom" style="color: #0093D0;"></span> -->
			</p>
		</div>
		<ul class="item-main">
			<li v-for="item in userList" :key="item.userName" v-show="(item.spaceRoleList.length!=0&&item.spaceRoleList[0].spaceRoleName != '客户')||item.spaceOwnUser==1">
				<i>
					<el-image :src="item.pictureUrl" class="headerImg" :onerror="errorImg"></el-image>
					<span>{{item.nickName}}</span>
					<i v-show="item.spaceRoleList.length!=0&&item.spaceRoleList[0].spaceRoleName == '管理员'&&item.spaceOwnUser!=1" style="margin-left: 10px;color: #B8CBD2;" class="iconfont icon-guanliyuan"></i>
					<i v-show="item.spaceRoleList.length!=0&&item.spaceRoleList[0].spaceRoleName == '成员'&&item.spaceOwnUser!=1" style="margin-left: 10px;color: #DBA26F;" class="iconfont icon-guanliyuan"></i>
					<i v-show="item.spaceOwnUser==1" style="margin-left: 10px;color: #FFC62E;" class="iconfont icon-chengyuan"></i>
				</i>
				<span class="toolRight">
					<!-- <i class="el-icon-edit pointer" @click="updateUserSpaceRoleMth(item)"></i>
					<i class="el-icon-delete pointer" @click="deleteUser(item)"></i> -->
				</span>
			</li>
			<li v-for="item in userList" :key="item.userName+'oo'"  v-show="item.spaceRoleList.length!=0&&item.spaceRoleList[0].spaceRoleName == '客户'">
				<i>
					<el-image :src="item.pictureUrl" class="headerImg" :onerror="errorImg"></el-image>
					<span>{{item.nickName}}</span>
				</i>
				<span class="toolRight">
					<i class="el-icon-edit pointer" @click="updateUserSpaceRoleMth(item)"></i>
					<i class="el-icon-delete pointer" @click="deleteUser(item)"></i>
				</span>
			</li>
		</ul>

		<el-dialog title="邀请成员" :visible.sync="dialogVisible" :close-on-click-modal="false">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

				<el-form-item label="用户名" prop="UserName">
					<el-input v-model="ruleForm.UserName"></el-input>
				</el-form-item>
				<div class="inline-box">
					<el-form-item label="空间角色" prop="SpaceRoleId">
						<el-select v-model="ruleForm.SpaceRoleId" placeholder="请选择">
							<el-option v-for="item in jurisdiction" :key="item.id" :label="item.spaceRoleName" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="项目">
						<el-select v-model="ruleForm.ProjectId" placeholder="请选择">
							<el-option v-for="item in pulluserprojectList" :key="item.id" :label="item.projectName" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</div>
			</el-form>
			<p style="text-align: right;">
				<el-button type="primary" icon="el-icon-success" @click="addMember">确定</el-button>
			</p>
		</el-dialog>
		<!-- 修改用户角色ID -->
		<el-dialog title="修改用户" :visible.sync="updateUserSpaceRole" width="30%" :close-on-click-modal="false">
			<div style="text-align:right;">
				<el-form ref="ruleForm3" :model="curFixData" :rules="fixUserRoleRules" label-width="100px" label-position="left" style="text-align: left;">
					<el-form-item>
						<el-input  v-model="curFixData.name" placeholder="用户名称" disabled />
					</el-form-item>

					<el-form-item label="角色" prop="spaceId">

						<el-select  v-model="curFixData.spaceId" placeholder="请选择">
							<el-option
							  v-for="item in SpaceRoleListHavePermiss "
							  :key="item.id"
							  :label="item.spaceRoleName"
							  :value="item.id">
							</el-option>
						 </el-select>
					</el-form-item>
				</el-form>
				<el-button type="danger" @click="updateUserSpaceRole=false">取消</el-button>
				<el-button type="primary" @click="updateUserSpaceRoleAPI">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    space: String
  },
  data () {
    return {
      position: false,
      userList: [],
      curUserID: localStorage.getItem('USER_ID'),
      curUserStatu: 0,
      curUserOwnId: 0, // 企业拥有者id
      errorImg: 'this.src="' + require('../assets/header.png') + '"',
      dialogVisible: false,
      pulluserprojectList: [],

      jurisdiction: [],
      curFixData: {
        name: '',
        spaceId: 0,
        userId: 0
      },
      ruleForm: {
        UserName: '',
        SpaceRoleId: '',
        ProjectId: ''
      },
      fixUserRoleRules: {
        UserName: {
          required: true,
          message: '用户名不能为空',
          trigger: 'blur'
        },
        spaceId: {
          required: true,
          message: '请选择角色',
          trigger: 'change'
        }
      },
      SpaceRoleListHavePermiss: [],
      updateUserSpaceRole: false,
      rules: {
        UserName: {
          required: true,
          message: '请填写用户名',
          trigger: 'blur'
        },
        juris: {
          required: true,
          message: '请选择所属项目',
          trigger: 'change'
        },
        SpaceRoleId: {
          required: true,
          message: '请选择空间角色',
          trigger: 'change'
        }
      }
    }
  },
  mounted () {
    this.getSpaceUser()
  },
  methods: {
    getSpaceUser () {
      // 获取空间用户
      this.$axios.get('/api/User/GetSpaceUserList').then(res => {
        this.userList = res
        var curUser = this.userList.findIndex(n => n.userId == this.curUserID)
        var curOwnUser = this.userList.findIndex(n => n.spaceOwnUser == 1)
        if (curOwnUser > -1) {
          this.curUserOwnId = this.userList[curOwnUser].userId
        }
        if (curUser > -1) {
          if (this.userList[curUser].spaceRoleList.length > 0) {
            this.curUserStatu = this.userList[curUser].spaceRoleList[0].statu
          }
        } else {
          // 能登陆进来，并且空间用户列表中没有此用户，说明此用户为超级管理员或企业拥有者
          this.curUserStatu = 100
        }
 				}),
				 // 空间角色
      this.$axios.get('/api/SpaceRole/GetAllSpaceRole').then(res => {
        this.SpaceRoleList = res
        this.roleListTemp = res
      })
      this.$axios.get('/api/Project/GetProjectBySpaceId?spaceId=' + localStorage.eleSpaceId).then(res => {
        // return
        var projectList = res
        this.pulluserprojectList = []
        for (var i = 0; i < projectList.length; i++) {
          projectList[i].positionNum = i + 1
          projectList[i].projectTypeId = projectList[i].id
          projectList[i].id = 'card' + projectList[i].id
          projectList[i].name = projectList[i].projectTypeName
          if (projectList[i].projectListDto && projectList[i].projectListDto.length > 0) {
            this.pulluserprojectList = this.pulluserprojectList.concat(projectList[i].projectListDto)
          }
        }
        this.projectList = projectList
      })
 			},
    pullUser () {
      this.dialogVisible = true
      this.ruleForm.ProjectId = ''
      this.ruleForm.UserName = ''
      this.ruleForm.SpaceRoleId = ''
      // this.$refs['ruleForm'].resetFields();
      this.jurisdiction = []
      this.$axios.get('/api/SpaceRole/GetAllSpaceRole').then(res => {
        // 如果是企业拥有者，可以修改任何权限
				    if (this.curUserOwnId == this.curUserID) {
          this.jurisdiction = res
			    	} else {
          for (var i = 0; i < res.length; i++) {
				    		// 如果角色权限小于该用户的权限，则可显示
				    		if (this.curUserStatu > res[i].statu) {
				    			this.jurisdiction.push(res[i])
				    		}
				    	}
        }
      })
    },
    updateUserSpaceRoleMth (item) {
      if (item.userId == this.curUserID) {
        this.$message({
          type: 'warning',
          message: '不能修改自己信息！'
        })
        return
      }
      // 如果是企业拥有者则不能修改
      if (item.spaceOwnUser == 1) {
        this.$message({
          type: 'warning',
          message: '不能修改企业拥有者！'
        })
        return
      }
      // 如果是企业拥有者，可以修改任何权限
      if (this.curUserOwnId == this.curUserID) {
        // 空间角色
				    this.$axios.get('/api/SpaceRole/GetAllSpaceRole').then(res => {
          this.SpaceRoleListHavePermiss = res
          this.curFixData.name = item.nickName
				        this.curFixData.userId = item.userId
				        this.curFixData.spaceId = item.spaceRoleList[0].id
				        this.updateUserSpaceRole = true
        })
      } else {
        if (this.curUserStatu > item.spaceRoleList[0].statu) {
          // 空间角色
				        this.$axios.get('/api/SpaceRole/GetAllSpaceRole').then(res => {
				    		this.SpaceRoleListHavePermiss = []
				    		if (this.curUserOwnId == this.curUserID) {
				    			this.SpaceRoleListHavePermiss = res
				    		} else {
				    		    for (var i = 0; i < res.length; i++) {
				    		    	// 如果角色权限小于该用户的权限，则可显示
				    		    	if (this.curUserStatu > res[i].statu) {
				    		    		this.SpaceRoleListHavePermiss.push(res[i])
				    		    	}
				    		    }
				    		}
				            this.curFixData.name = item.nickName
				            this.curFixData.userId = item.userId
				            this.curFixData.spaceId = item.spaceRoleList[0].id
				            this.updateUserSpaceRole = true
				        })
				    } else {
				    	this.$message({
				    		type: 'warning',
				    		message: '您没有权限修改此用户！'
				    	})
				    }
      }
    },
    updateUserSpaceRoleAPI () {
      this.$axios.get(`/api/user/updatespaceroleid?userId=${this.curFixData.userId}&spaceRoleId=${this.curFixData.spaceId}`).then(res => {
        if (res == '更新成功') {
          for (var key in this.userList) {
            if (this.userList[key].userId == this.curFixData.userId) {
              var findValue = this.SpaceRoleList.find(item => {
                return item.id == this.curFixData.spaceId
              })
              this.userList[key].spaceRoleList[0] = findValue
            }
          }
          this.updateUserSpaceRole = false
          this.$message({
            type: 'success',
            message: '修改成功'
          })
        }
      })
    },
    deleteUser (item) {
      if (item.userId == this.curUserID) {
        this.$message({
          type: 'warning',
          message: '不能删除自己！'
        })
        return
      }
      // 如果是企业拥有者则不能修改
      if (item.spaceOwnUser == 1) {
        this.$message({
          type: 'warning',
          message: '不能删除企业拥有者！'
        })
        return
      }
      // 企业拥有者，可以修改任何权限
      if (this.curUserOwnId == this.curUserID || this.curUserStatu > item.spaceRoleList[0].statu) {
        this.$confirm(`此操作将删除该企业用户，同时移出该企业下用户所有所在的项目, 是否继续?`, '提示', {
				        	confirmButtonText: '确定',
				        	cancelButtonText: '取消',
				        	type: 'warning'
				        }).then(() => {
				        	this.$axios.get('/api/Space/DeleteSpaceUser?UserId=' + item.userId).then(res => {
				        		this.$message({
				        			type: 'success',
				        			message: '删除成功!'
				        		})
				        		this.getSpaceUser()
				        	})
				        })
      } else {
				    this.$message({
				    	type: 'warning',
				    	message: '您没有权限删除此用户！'
				    })
      }
    },
    sort (arr, pos) {
      if (pos) {
        arr.sort((a, b) => a['nickName'] < b['nickName'] ? 1 : -1)
      } else {
        arr.sort((a, b) => a['nickName'] > b['nickName'] ? 1 : -1)
      }
      this.position = !this.position
    },
    addMember () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          var pullInfo = this.$utils.deepClone(this.ruleForm)
          pullInfo.ProjectId = pullInfo.ProjectId == '' ? 0 : pullInfo.ProjectId
          this.$axios.post('/api/Space/PullSpaceOrProjectUser', pullInfo).then(res => {
				        	this.getSpaceUser()
            this.$message({
              type: 'success',
              message: '邀请用户成功'
            })
            this.dialogVisible = false
            this.ruleForm.ProjectId = ''
            // this.$refs['ruleForm'].resetFields();
				        })
        } else {
          return false
        }
      })
    }

  }
}
</script>

<style lang="less">
	.com {
	    .item-header {
	    	display: flex;
	    	height: 47px;
	    	background-color: #F8F8F9;
	    	justify-content: space-between;
	    	align-items: center;
	    	box-sizing: border-box;
	    	font-weight: bold;
	    	padding: 0 30px;

	    	p {
	    		span {
	    			cursor: pointer;
	    			margin: 0 5px;
	    		}
	    	}
	    }

	    .item-main {
	    	padding: 0 20px;
	    	background-color: #FFFFFF;
	    	height: calc(100vh - 138px);
	    	overflow-y: auto;
	    	.headerImg {
	    		border-radius: 30px;
	    	}
	    	.toolRight {
	    		display: flex;
	    		i {
	    			margin-right: 10px;
	    		}
	    	}
	    	.kehu {
	    		padding: 15px 5px 15px 20px;
	    		margin: 25px 0 5px 0;
	    		text-align: left;
	    		border: 1px solid #475065;
	    		border-radius: 5px;

	    	}
	    	li {
	    		display: flex;
	    		align-items: center;
	    		justify-content: space-between;
	    		height: 70px;
	    		border-bottom: 1px solid #e1e1e1;
	    		padding: 0 10px;
	    		font-weight: bold;

	    		i {
	    			display: flex;
	    			align-items: center;

	    			img {
	    				height: 50px;
	    				width: 50px;
	    			}

	    			span {
	    				margin-left: 15px;
	    			}
	    		}

	    	}
	    }

	    .margin-top {
	    	padding-top:5px
	    }
	    .el-tree {
	    	.el-tree-node__content{
	    		height: unset;
	    		border-bottom: 1px solid #e1e1e1;
	    	}
	    	.el-tree-node__expand-icon{
                position :absolute;
                right: 2%
            }
	        .el-tree-node__label{
                padding-left: 15px;
            }
	    	.item-tree {
	    		padding-left: 15px;
	        	height: 40px;
	    		color: #2c3e50;
	    		margin-top: 23px;
	    		font-size: 16px;
	    		font-weight: bold
	        }
	    }
	}
</style>
